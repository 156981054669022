import { useEffect, useState } from "react";
import DialogAdd from "./components/DialogAdd";
import HRCard from "./components/HRCard";
import { ADDHR, GET, GETDATAHR, POST, SELECTORHR } from "../../service";
import Swal from "sweetalert2";
import axios from "axios";
import HRTblCard from "./components/HRTblCard";

function HumanResources() {
    const [loading, setLoading] = useState(false);
    const [selectHR, setSelectHR] = useState();
    const [selectLevel, setSelectLevel] = useState();
    const [selectParent, setSelectParent] = useState();
    const [dataHR, setDataHR] = useState([]);
    const [ampCode, setAmpCode] = useState("");
    const [hcode, setHcode] = useState("");

    const getDataHR = async () => {
        try {
            setLoading(true);
            let res = await POST(GETDATAHR, {
                ampCode,
                hcode,
            });
            if (res.success) {
                setDataHR(res.result.treeDiagram);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getSelectHR = async () => {
        try {
            setLoading(true);
            let res = await GET(SELECTORHR);
            if (res.success) {
                setSelectHR(res.result.position);
                setSelectLevel(res.result.level);
                setSelectParent(res.result.parent);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getSelectHR();
        getDataHR();
    }, []);

    return (
        <>
            <HRCard data={dataHR} />
        </>
    );
}

export default HumanResources;
