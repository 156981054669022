import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import HRFamilyTree from "./HRFamilyTree";
import { PersonIcon } from "../../../untils/icons";

function HRCard({ data }) {
    // console.log("dataHR:", data);
    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={8} sm={6} md={6} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <PersonIcon fontSize="medium" />
                            <Typography className="main-header">ทรัพยากรบุคคล</Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} my={2}>
                    <HRFamilyTree dataTree={data} />
                </Grid>
            </Paper>
        </Box>
    );
}

export default HRCard;
