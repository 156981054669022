import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { ButtonTeal } from "../../../Theme";
import { AddItemIcon, PersonIcon } from "../../../untils/icons";
import GridTable from "./GridTable";

function HRTblCard({ handleClickOpenEdit, handleClickOpenAdd, data, header, handleDelete }) {
    // console.log("dataHR:", data);
    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={8} sm={6} md={6} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <PersonIcon fontSize="medium" />
                            <Typography className="main-header">ข้อมูลทรัพยากรบุคคล</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={6} md={6} className="flex-end">
                        <Stack direction="row" spacing={1}>
                            <ButtonTeal onClick={handleClickOpenAdd}>
                                <AddItemIcon fontSize="small" />
                                &nbsp;
                                <Typography sx={{ display: { xs: "none", sm: "block" }, cursor: "pointer" }}>เพิ่มข้อมูล</Typography>
                            </ButtonTeal>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <GridTable handleClickOpenEdit={handleClickOpenEdit} data={data} header={header} handleDelete={handleDelete} />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default HRTblCard;
