import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React, { useRef } from "react";

import { ButtonTeal } from "../../../Theme";
import { CloseIcn } from "../../../untils/icons";

export default function DialogAdd({
    open,
    handleClose,
    handleAdd,
    handleFileChange,
    selectHR,
    selectLevel,
    selectParent,
    position,
    setPosition,
    level,
    setLevel,
    name,
    setName,
    salary,
    setSalary,
    tel,
    setTel,
    parent,
    setParent,
    address,
    setAddress,
    isRoot,
    dataHR,
}) {
    const formRef = useRef();

    const checkValidation = () => {
        if (formRef.current.reportValidity()) {
            handleClose();
            handleAdd();
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} className="bg-dialog">
            <Box className="bg-gradiant">
                <DialogActions className="flex-end">
                    <CloseIcn className="close-icn" onClick={handleClose} />
                </DialogActions>
                <DialogTitle className="text-header">เพิ่มข้อมูลทรัพยากรบุคคล</DialogTitle>
                <DialogContent className="flex-center">
                    {/* Button to add test data */}
                    {/* <Button
                        onClick={() => {
                            setPosition(1);
                            setLevel(1);
                            setName("เด็ดเดี่ยว คนที่");
                            setSalary(15000);
                            setTel("0987654321");
                            // setParent();
                            setAddress("บ้านเลขที่ ");
                        }}
                    >
                        Add Data
                    </Button> */}
                    <form ref={formRef} className="flex-center">
                        <DialogContentText mt={2} className="container-bg" component={"span"}>
                            <Grid container columns={12} spacing={2}>
                                <Grid item xs={12}>
                                    <input type="file" onChange={handleFileChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ชื่อ"
                                        name="name"
                                        variant="outlined"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth={true} required>
                                        <InputLabel>ตำแหน่ง</InputLabel>
                                        <Select name="position" value={position} label="เลือกตำแหน่ง" onChange={(e) => setPosition(e.target.value)}>
                                            {Array.isArray(selectHR) &&
                                                selectHR.map((item, index) => (
                                                    <MenuItem key={index} value={item.position_id}>
                                                        {item.position_name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth={true} required>
                                        <InputLabel>ระดับ</InputLabel>
                                        <Select name="level" value={level} label="เลือกระดับ" required onChange={(e) => setLevel(e.target.value)}>
                                            {Array.isArray(selectLevel) &&
                                                selectLevel.map((item, index) => (
                                                    <MenuItem key={index} value={item.level_id}>
                                                        {item.level_name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="เงินเดือน"
                                        name="salary"
                                        variant="outlined"
                                        value={salary}
                                        onChange={(e) => setSalary(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="เบอร์โทร"
                                        name="tel"
                                        variant="outlined"
                                        value={tel}
                                        onChange={(e) => setTel(e.target.value)}
                                    />
                                </Grid>
                                {isRoot && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth={true} required>
                                            <InputLabel>หัวหน้า</InputLabel>
                                            <Select name="parent" value={parent} label="เลือกหัวหน้า" onChange={(e) => setParent(e.target.value)}>
                                                {Array.isArray(selectParent) &&
                                                    selectParent.map((item, index) => (
                                                        <MenuItem key={index} value={item.staff_id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ที่อยู่"
                                        name="address"
                                        variant="outlined"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions className="flex-center">
                    <ButtonTeal sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }} onClick={checkValidation}>
                        เพิ่มข้อมูล
                    </ButtonTeal>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
