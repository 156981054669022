import React, { useState } from "react";
import Tree from "react-d3-tree";
import "../../../css/custom-tree.css";

export default function HRFamilyTree({ dataTree }) {
    const initialTranslate = { x: 700, y: 100 };
    const pathapi = process.env.REACT_APP_SERVICE;
    const token = localStorage.getItem("uuid");
    const profile = localStorage.getItem("profile");
    // console.log("profile:", profile);

    const straightPathFunc = (linkDatum, orientation) => {
        const { source, target } = linkDatum;
        if (orientation === "horizontal") {
            // เส้นแนวนอน
            return `M${source.x},${source.y}L${target.x},${target.y}`;
        } else {
            // เส้นแนวตั้ง
            return `M${source.x},${source.y}L${target.x},${target.y}`;
        }
    };

    if (dataTree && Array.isArray(dataTree) && dataTree.length > 0) {
        return (
            <div
                id="treeWrapper"
                style={{
                    display: "flex",
                    maxWidth: "100vw",
                    maxHeight: "100vh",
                    height: "100vh",
                    overflow: "hidden",
                }}
            >
                <Tree
                    data={dataTree}
                    rootNodeClassName="node__root"
                    branchNodeClassName="node__branch"
                    leafNodeClassName="node__leaf"
                    orientation="vertical"
                    nodeSize={{ x: 300, y: 250 }}
                    translate={initialTranslate}
                    scaleExtent={{ max: 0.8, min: 0.5 }}
                    // pathFunc={straightPathFunc}
                    renderCustomNodeElement={({ nodeDatum }) => {
                        return (
                            <foreignObject width="200" height="300" x="-100" y="-100">
                                <div style={{ textAlign: "center" }}>
                                    {/* Image Styling */}
                                    <img
                                        src={`${pathapi}${nodeDatum.imgpath}?secret_key=${token}&data=${profile}`}
                                        alt="person"
                                        style={{
                                            width: "150px",
                                            height: "150px",
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                            marginTop: "10px",
                                        }}
                                    />
                                    <div
                                        style={{
                                            marginBottom: "5px",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            padding: "1px",
                                            backgroundColor: "#f0f0f0",
                                            border: "2px solid #000",
                                            borderRadius: "5px",
                                            textAlign: "center",
                                        }}
                                    >
                                        <p style={{ margin: "5px 0", fontSize: "16px", lineHeight: "1.5" }}>
                                            <strong>ชื่อ:</strong> {nodeDatum.name}
                                        </p>
                                        <p style={{ margin: "5px 0", fontSize: "14px", color: "#666" }}>
                                            <strong>ตำแหน่ง:</strong> {nodeDatum.position}
                                        </p>
                                    </div>
                                </div>
                            </foreignObject>
                        );
                    }}
                />
            </div>
        );
    }
}
